<!--
 * @心中有党，事业理想: 
 * @Author: Petrichor
 * @Date: 2022-05-05 20:52:33
 * @LastEditors: Petrichor
 * @LastEditTime: 2022-05-10 14:04:37
 * @Description: file content
 * @FilePath: \eart_pc\src\components\footer.vue
-->
<template>
  <div class="footer-main">
    <div class="info">
      <div>
        <div>京ICP备20019303号</div>
        <div>主办单位：北京海淀教育融媒体中心</div>
        <div>联系电话：010-84874736</div>
      </div>
      <div style="margin-left: 6vw">
        <div>Copyright&copy;2020北京一呈观云</div>
        <div>承办单位：北京一呈观云科技有限公司</div>
        <div>地址：北京市朝阳区317号金泉时代1单元</div>
      </div>
    </div>
    <a-divider type="vertical" style="border-left: 1px solid #979797" />
    <div class="logo">
      <img src="@/assets/logo.png" />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
@import "../index.less";
.footer-main {
  background: #f3f3f3;
  display: flex;
  align-items: center;
  padding: @main-padding;
  padding-top: 20px;
  padding-bottom: 20px;
  .info {
    flex: 4;
    color: #979797;
    display: flex;
    font-size: 12px;
    div {
      text-align: left;
      margin-bottom: 3px;
    }
  }
  .logo {
    flex: 1;
    img {
      height: 40px;
    }
  }
}
</style>
