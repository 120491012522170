import type { AxiosRequestConfig, AxiosError } from "axios";
import axios, { AxiosResponse } from "axios";
import { message } from "ant-design-vue";
import { getToken } from "@/utils/auth";

export interface ResponseBody<T = any> {
  message: string;
  code: number;
  data?: T | T[];
}

// 这里是用于设定请求后端时，所用的 Token KEY
// 可以根据自己的需要修改，常见的如 Access-Token，Authorization
// 需要注意的是，请尽量保证使用中横线`-` 来作为分隔符，
// 避免被 nginx 等负载均衡器丢弃了自定义的请求头
export const REQUEST_TOKEN_KEY = "Access-Token";
// 创建 axios 实例
const service = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_EADM_API,
  timeout: 6000,
  withCredentials: true,
});
axios.defaults.withCredentials = true;
// 异常拦截处理器
const errorHandler = (error: AxiosError): Promise<any> => {
  if (error.response) {
    const { data = {}, status, statusText } = error.response;
    // 403 无权限
    // if (status === 403) {
    //   notification.error({
    //     message: "Forbidden",
    //     description: (data && data.message) || statusText,
    //   });
    // }
  }
  return Promise.reject(error);
};

// 请求拦截器

const requestHandler = (
  config: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  if (config.headers) {
    config.headers["Access-Control-Allow-Origin"] = `${location.origin}`;
    config.headers["Access-Control-Allow-Headers"] =
      "Origin, X-Requested-With, Content-Type, Accept, Access-Token";

    // 如果 token 存在
    //let token = localStorage.getItem('token');
    const savedToken = getToken() + "";
    if (savedToken.length) {
      config.headers[REQUEST_TOKEN_KEY] = savedToken;
    }
  }
  return config;
};

// Add a request interceptor
service.interceptors.request.use(requestHandler, errorHandler);

// 响应拦截器
const responseHandler = (
  response: AxiosResponse
): ResponseBody<any> | AxiosResponse<any> | Promise<any> | any => {
  //console.log(67, response);
  // if (
  //     (response && response.data.code === 2001) ||
  //     response.data.code === 1004 ||
  //     response.data.code === 410
  // ) {
  //     message.error(response.data.msg);
  //     window.location.pathname = `/user`;
  //     localStorage.clear();
  // }
  return response.data;
};

// Add a response interceptor
service.interceptors.response.use(responseHandler, errorHandler);

export { AxiosResponse };

export default service;
