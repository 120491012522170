
import { defineComponent, reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const state = reactive({
      rootSubmenuKeys: ["sub1"],
      openKeys: ["sub1"],
      collapsed: false,
      selectedKeys: [""],
    });
    const onOpenChange = (openKeys: string[]) => {
      const latestOpenKey = openKeys.find(
        (key) => state.openKeys.indexOf(key) === -1
      );
      if (state.rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
        state.openKeys = openKeys;
      } else {
        state.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    };
    //
    const router = useRouter();
    const clickMen = (url: string) => {
      if (url == "/signOut") {
        localStorage.clear();
        router.push(`/user`);
        return;
      }
      router.push(url);
    };
    const route = useRoute();
    if (route.name == "Team") {
      state.selectedKeys = ["1"];
    } else if (route.name == "Team") {
      state.selectedKeys = ["2"];
    } else if (route.name == "Release") {
      state.selectedKeys = ["3"];
    } else if (route.name == "comment") {
      state.selectedKeys = ["4"];
    } else if (route.name == "Follow") {
      state.selectedKeys = ["5"];
    }
    //  else if (route.name == 'PublishList') {
    //   state.selectedKeys = ['5'];
    // }
    return {
      ...toRefs(state),
      onOpenChange,
      clickMen,
    };
  },
});
