import request from "@/utils/request";
export const register = (data: any) => request.post("public/registry", data);
export const signIn = (data: any) => request.post("public/signin", data);
//发送验证码
export const sendCode = (data: any) => request.post("public/ycode", data);
//个人中心修改资料/eart/account/reset
export const editUserInformation = (data: any) =>
  request.post("public/user/edit", data);
//作者下的分类
export const artCategorys = (data: any) =>
  request.post("public/artist/categorys", data);
//评论列表
export const commentApi = (data: any) =>
  request.post("public/user/comments", data);
//关注作品列表
export const followApi = (data: any) =>
  request.post("public/follow/list", data);
//follow/cancel
export const cancelFollowApi = (data: any) =>
  request.post("public/follow/cancel", data);
