/*
 * @心中有党，事业理想:
 * @Author: Petrichor
 * @Date: 2022-05-10 17:05:05
 * @LastEditors: Petrichor
 * @LastEditTime: 2022-05-11 15:21:18
 * @Description: file content
 * @FilePath: \eart_pc\src\utils\common.ts
 */
export const handleData = (data: any[], conut = 3) => {
  const list = [];
  list.push(...data);
  const num = data.length % conut;
  if (num) {
    for (let i = 0; i < conut - num; i++) {
      list.push({});
    }
  }
  return list;
};
