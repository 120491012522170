<template>
  <section class="user-body">
    <h3 class="set-title">评论列表</h3>
    <ul
      v-if="commentList && commentList.value && commentList.value.length != 0"
    >
      <li
        class="d-comment-item"
        v-for="(item, index) in commentList.value"
        :key="index"
      >
        <img src="@/assets/user_icon.png" />
        <hgroup>
          <h3>{{ item.artist_name }}</h3>
          <h5>{{ item.remark }}</h5>
          <p class="date">{{ item.create_at }}</p>
        </hgroup>
        <p class="del-icon" @click="del"><delete-outlined /></p>
      </li>
    </ul>
    <div class="no-data" v-else>暂时没有评论</div>
    <div class="d-pagination">
      <a-pagination
        v-model:current="currentNum"
        show-quick-jumper
        :total="total"
        @change="onChange"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import { message } from "ant-design-vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
import { commentApi } from "@/api/user";

let currentNum = ref(1);
let total = ref(0);
const commentList = reactive([]);

// 获取数据
const getData = () => {
  commentApi({ page: currentNum.value, size: 10 }).then((res) => {
    if (res.code === 200) {
      commentList.value = res.data.list;
      total.value = res.data.total;
    }
  });
};
const onChange = (pageNumber) => {
  currentNum.value = pageNumber;
  getData();
};
//删除
const del = (id) => {
  //
};
onMounted(() => {
  getData();
});
</script>
<style lang="less" scoped>
.user-body {
  text-align: left;
  padding-left: 50px;
  .set-title {
    color: #333;
    padding-left: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 15px;
    border-left: 2px solid #e74a48;
  }
  .d-comment-item {
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dbdbdb;
    img {
      width: 70px;
      height: 70px;
    }
    hgroup {
      flex: 1;
      margin: 0 15px;
      h5 {
        line-height: 10px;
        color: #585858;
      }
    }
    .date {
      color: #8e8e8e;
    }
  }
  .no-data {
    margin: 30px;
    text-align: center;
    color: #666;
  }
  .d-pagination {
    text-align: center;
  }
  .del-icon {
    cursor: pointer;
  }
}
</style>
