
import { defineComponent, reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import ReleaseInformation from "./ReleaseInformation.vue";
import PublishList from "./PublishList.vue";
export default defineComponent({
  setup() {
    const state = reactive({
      collapsed: false,
      selectedKeys: ["1"],
      currentMenu: "1",
    });
    const router = useRouter();
    const clickMen = (num: string) => {
      state.currentMenu = num;
    };
    return {
      ...toRefs(state),
      clickMen,
    };
  },
  components: {
    ReleaseInformation,
    PublishList,
  },
});
