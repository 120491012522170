<template>
  <section class="user-body">
    <h3 class="set-title">发布列表</h3>
    <div class="top">
      <p class="publish-tabs">
        <span :class="{ active: currentTab === 1 }" @click="toggleTab(1)"
          >个人作品</span
        >
        <span :class="{ active: currentTab === 2 }" @click="toggleTab(2)"
          >展览</span
        >
        <span :class="{ active: currentTab === 3 }" @click="toggleTab(3)"
          >VR</span
        >
      </p>
      <a-input
        v-model:value="name"
        allowClear
        class="publish-search-input"
        size="small"
      >
        <template #prefix>
          <search-outlined @click="search" />
        </template>
      </a-input>
    </div>
    <div
      v-for="(item, index) in publishList.value"
      :key="index"
      class="publish-tab-content"
    >
      <img :src="item.logo" class="logo" />
      <div class="text-group">
        <div class="title">
          {{ item.name }}<span class="status-text">{{ item.status_text }}</span>
        </div>
        <div class="summary" v-html="item.description"></div>
      </div>
      <div class="del" @click="del(item.id)">删除</div>
    </div>
    <!-- <ul>
      <li></li>
      <li></li>
      <li></li>
    </ul> -->
    <!-- <div class="no-data">暂无数据</div> -->
    <!-- <div class="d-pagination">
      <a-pagination v-model:current="currentNum" show-quick-jumper :total="total"
        @change="onChange" />
    </div> -->
  </section>
</template>
<script setup>
import { ref, reactive, onMounted, createVNode } from "vue";
import { message, Modal } from "ant-design-vue";
import {
  SearchOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import {
  getOpusList,
  getShowroomList,
  getVRList,
  publishDelete,
} from "@/api/newApi";

const currentTab = ref(1);
const publishList = reactive([]);
const name = ref(``);

const toggleTab = (tabIndex) => {
  currentTab.value = tabIndex;
  if (tabIndex == 1) {
    getData(getOpusList);
  } else if (tabIndex == 2) {
    getData(getShowroomList);
  } else if (tabIndex == 3) {
    getData(getVRList);
  }
};
// 获取数据
const getData = (currentURL) => {
  let id = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData")).id
    : "";
  currentURL({ user_id: id, name: name.value }).then((res) => {
    if (res.code === 200) {
      publishList.value = res.data.data;
    }
  });
};
const search = () => {
  if (currentTab.value == 1) {
    getData(getOpusList);
  } else if (currentTab.value == 2) {
    getData(getShowroomList);
  } else if (currentTab.value == 3) {
    getData(getVRList);
  }
};
const del = (id) => {
  let delType;
  if (currentTab.value == 1) {
    delType = "opus";
  } else if (currentTab.value == 2) {
    delType = "vr";
  } else if (currentTab.value == 3) {
    delType = "showroom";
  }
  Modal.confirm({
    title: "确认删除",
    icon: createVNode(ExclamationCircleOutlined),
    content: "请确认要删除吗?",
    okText: "删除",
    okType: "danger",
    cancelText: "取消",
    onOk() {
      publishDelete({ id: id, type: delType }).then((res) => {
        if (res.code == 200) {
          message.success(res.msg);
          search();
        }
      });
    },
    onCancel() {
      //console.log('Cancel');
    },
  });
};
onMounted(() => {
  getData(getOpusList);
});
</script>
<style lang="less" scoped>
.user-body {
  text-align: left;
  padding-left: 50px;
  flex: 1;

  .set-title {
    color: #333;
    padding-left: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 15px;
    border-left: 2px solid #e74a48;
  }

  .top {
    display: flex;
  }

  .publish-tabs {
    span {
      color: #999;
      cursor: pointer;
      padding: 10px 20px;
    }

    span.active {
      color: #111;
      border-bottom: 2px solid #e74a48;
    }
  }

  .publish-search-input {
    width: 200px;
    margin-left: auto;
    border: none;
    border-bottom: 1px solid #e3e3e3;
  }

  .publish-tab-content {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e3e3e3;

    .logo {
      width: 120px;
      height: 120px;
      margin-right: 15px;
    }

    .text-group {
      padding-top: 8px;

      .title {
        margin-bottom: 15px;
      }

      .status-text {
        color: #999;
        padding: 3px 8px;
        margin-left: 9px;
        border: 1px solid #e3e3e3;
      }

      .summary {
        color: #999;
      }
    }

    .del {
      width: 50px;
      height: 24px;
      cursor: pointer;
      border-radius: 20px;
      margin-left: auto;
      margin-top: 20px;
      color: #e74a48;
      text-align: center;
      border: 1px solid #e74a48;
    }
  }
}
</style>
