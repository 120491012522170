
import { defineComponent, onMounted, ref } from "vue";
import type { Ref } from "vue";
import { postGetRotation } from "@/api/index";
import { getHomeVr } from "@/api/newApi";
import { handleData } from "@/utils/common";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const imgList: Ref<any[]> = ref([]);
    const showRoomList: Ref<any[]> = ref([]);
    const router = useRouter();
    const getRotation = () => {
      postGetRotation({}).then((res: any) => {
        if (res.code === 200) {
          imgList.value = res.data;
        }
      });
    };
    const getVr = () => {
      getHomeVr({}).then((res: any) => {
        if (res.code === 200) {
          showRoomList.value = handleData(res.data.list || []);
        }
      });
    };
    const goUrl = () => {
      router.push("/vrList");
    };
    const goDetails = (url: string | number) =>
      (window.location.href = url + "");
    const goCarousel = (url: string, id: string | number) => {
      if (url === "") {
        goDetails(id);
      } else {
        window.open(url);
      }
    };
    onMounted(() => {
      getRotation();
      getVr();
    });
    return {
      imgList,
      showRoomList,
      imgUrl: process.env.VUE_APP_IMG_API,
      goUrl,
      goDetails,
      goCarousel,
    };
  },
});
