import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
/*
 * @心中有党，事业理想:
 * @Author: Petrichor
 * @Date: 2022-03-21 19:18:12
 * @LastEditors: Petrichor
 * @LastEditTime: 2022-05-10 15:49:34
 * @Description: file content
 * @FilePath: \eart_pc\src\main.ts
 */
import "ant-design-vue/dist/antd.less";
import {
  Layout,
  Menu,
  Row,
  Col,
  Card,
  Form,
  Dropdown,
  Select,
  Button,
  Checkbox,
  Tabs,
  Tag,
  Input,
  DatePicker,
  TimePicker,
  Radio,
  Tooltip,
  Space,
  Steps,
  Divider,
  Descriptions,
  Alert,
  Result,
  Statistic,
  Popconfirm,
  Popover,
  Table,
  Avatar,
  List,
  Progress,
  Switch,
  Modal,
  Rate,
  ConfigProvider,
  Empty,
  Spin,
  Drawer,
  PageHeader,
  Carousel,
  BackTop,
  Upload,
  Badge,
  Timeline,
  Collapse,
  Image,
  Pagination,
} from "ant-design-vue";

// router.beforeEach(async (to, from, next) => {
//   //console.log(to, from, next);
//   if ((!getToken() && to.name !== "login") || to.fullPath === "/index.html") {
//     router.push("/login");
//   } else {
//     next();
//   }

//   // document.title = getPageTitle(to.meta.title);
//   // next();
//   // set page title
// });
const app = createApp(App);
app
  .use(ElementPlus)
  .use(router)
  .use(store)
  .use(Layout)
  .use(Pagination)
  .use(Menu)
  .use(Row)
  .use(Col)
  .use(Card)
  .use(Form)
  .use(Dropdown)
  .use(Select)
  .use(Button)
  .use(Checkbox)
  .use(Tabs)
  .use(Tag)
  .use(Input)
  .use(DatePicker)
  .use(TimePicker)
  .use(Radio)
  .use(Tooltip)
  .use(Space)
  .use(Steps)
  .use(Divider)
  .use(Descriptions)
  .use(Alert)
  .use(Result)
  .use(Statistic)
  .use(Popconfirm)
  .use(Popover)
  .use(Table)
  .use(Avatar)
  .use(List)
  .use(Progress)
  .use(Switch)
  .use(Modal)
  .use(Rate)
  .use(ConfigProvider)
  .use(Empty)
  .use(Spin)
  .use(Drawer)
  .use(PageHeader)
  .use(Badge)
  .use(Carousel)
  .use(BackTop)
  .use(Upload)
  .use(Timeline)
  .use(Image)
  .use(Collapse);
app.mount("#app");
