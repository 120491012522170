import Cookies from "js-cookie";

// const TokenKey = "HYPERF_SESSION_ID";

// export function getToken() {
//   return Cookies.get(TokenKey);
// }

// export function setToken(token: any) {
//   return Cookies.set(TokenKey, token);
// }

// export function removeToken() {
//   return Cookies.remove(TokenKey);
// }
export const setToken = (name: string, token: any) => Cookies.set(name, token);

export const getToken = (name = "HYPERF_SESSION_ID") => Cookies.get(name) || "";
