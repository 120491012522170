/*
 * @心中有党，事业理想:
 * @Author: Petrichor
 * @Date: 2022-05-06 18:07:04
 * @LastEditors: Petrichor
 * @LastEditTime: 2022-05-12 15:37:54
 * @Description: file content
 * @FilePath: \eart_pc\src\api\index.ts
 */
import request from "@/utils/request";

export const postGetRotation = (data: any) =>
  request.post("public/rotation", data);

export const postGetShowroom = (data: any) =>
  request.post("public/showroom", data);

export const postGetSearch = (data: any) => request.post("public/search", data);

export const postGetShowRoomList = (data: any) =>
  request.post("public/showroom/list", data);

export const postGetShowRoomDetails = (data: any) =>
  request.post("public/showroom/info", data);

export const postGetVRList = (data: any) => request.post("public/vr", data);

export const postGetOpusList = (data: any) =>
  request.post("public/opus/quality", data);

export const postGetCategory = (data: any) =>
  request.post("public/category", data);

export const postGetTeamList = (data: any) =>
  request.post("public/team/list", data);

export const postGetTeamInfo = (data: any) =>
  request.post("public/team/info", data);

export const postGetTeamArtists = (data: any) =>
  request.post("public/team/artists", data);
//作品明细
export const getOpusInfo = (data: any) =>
  request.post("public/opus/info", data);
//作品评论
export const getOpusComments = (data: any) =>
  request.post("public/opus/comments", data);
//点赞
export const getOpusPopular = (data: any) =>
  request.post("public/opus/popular", data);
//作品评论提交
export const opusCommentSubmit = (data: any) =>
  request.post("public/opus/submit", data);
