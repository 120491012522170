<!--
 * @心中有党，事业理想: 
 * @Author: Petrichor
 * @Date: 2022-05-05 20:52:10
 * @LastEditors: Petrichor
 * @LastEditTime: 2022-05-11 23:30:09
 * @Description: file content
 * @FilePath: \eart_pc\src\components\header.vue
-->
<template>
  <div class="header-main">
    <img class="logo" src="@/assets/logo.png" />
    <a-menu v-model:selectedKeys="current" mode="horizontal">
      <a-menu-item key="/">
        <router-link to="/">首页</router-link>
      </a-menu-item>
      <a-menu-item key="/opusList">
        <router-link to="/opusList">作品</router-link>
      </a-menu-item>
      <a-menu-item key="/showroomList">
        <router-link to="/showroomList">展览</router-link>
      </a-menu-item>
      <a-menu-item key="/vrList">
        <router-link to="/vrList">VR观展</router-link>
      </a-menu-item>
    </a-menu>
    <a-divider type="vertical" style="border-left: 1px solid #979797" />
    <search-outlined
      v-if="!isShow"
      class="search-icon"
      :class="{ move: isMove }"
      @click="showInput"
    />
    <a-input
      v-else
      allowClear
      v-model:value="keyword"
      size="small"
      class="input"
    >
      <template #prefix>
        <search-outlined @click="search" />
      </template>
    </a-input>
    <div v-if="!isShowPerson">
      <a-button
        danger
        style="margin-right: 12px"
        size="small"
        @click="goLogin(0)"
        >登录</a-button
      >
      <a-button type="primary" @click="goLogin(1)" size="small">注册</a-button>
    </div>
    <a v-else @click="toPerson">个人中心</a>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref,
  watch,
  watchEffect,
  onMounted,
  onUnmounted,
} from "vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import { useRoute, useRouter } from "vue-router";
export default {
  components: {
    SearchOutlined,
  },
  setup() {
    const current = ref([window.location.pathname]);
    const isMove = ref(false);
    const isShow = ref(false);
    const keyword = ref("");
    const isShowPerson = ref(false);
    const state = reactive({
      count: 0,
    });
    const router = useRouter();
    const route = useRoute();
    const goLogin = (flag) => {
      router.push(`/user?flag=${flag}`);
    };
    const showInput = () => {
      isMove.value = true;
      setTimeout(() => {
        isShow.value = true;
      }, 400);
    };
    const search = () => {
      router.push(`/search?keyword=${keyword.value}`);
    };
    const toPerson = () => {
      router.push(`/Setting`);
    };
    const keyDown = (e) => {
      if (e.keyCode == 13) {
        search();
      }
    };
    watch(route, () => {
      current.value = [window.location.pathname];
    });
    watchEffect((route) => {
      if (localStorage.getItem("token") != null) {
        isShowPerson.value = true;
      }
    });
    onMounted(() => {
      window.addEventListener("keydown", keyDown);
    });
    onUnmounted(() => {
      window.removeEventListener("keydown", keyDown, false);
    });
    return {
      ...toRefs(state),
      current,
      goLogin,
      isMove,
      showInput,
      isShow,
      keyword,
      search,
      isShowPerson,
      toPerson,
      keyDown,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../index.less";
.header-main {
  height: 60px;
  padding: @main-padding;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .logo {
    height: 40px;
  }
  :deep(.ant-menu-light) {
    height: 100%;
    .ant-menu-item {
      width: 100px;
      text-align: center;
      line-height: 60px;
      &:after {
        width: 100%;
        transform: translateX(-20px);
      }
    }
    .ant-menu-item-selected {
      background: @main-light-color;
    }
  }
  .search-icon {
    cursor: pointer;
    width: 160px;
  }
  .move {
    animation: animte 0.5s linear infinite;
  }
  .input {
    width: 160px;
  }
}
@keyframes animte {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-85px);
  }
}
</style>
