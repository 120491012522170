/*
 * @心中有党，事业理想:
 * @Author: Petrichor
 * @Date: 2022-05-06 18:04:22
 * @LastEditors: Petrichor
 * @LastEditTime: 2022-05-06 18:04:23
 * @Description: file content
 * @FilePath: \eart_pc\src\utils\request.ts
 */
import type { AxiosRequestConfig, AxiosError } from "axios";
import axios, { AxiosResponse } from "axios";
// import { localStorage } from '@/utils/local-storage';
// import { STORAGE_TOKEN_KEY } from '@/store/mutation-type';
import { notification, message } from "ant-design-vue";
import { getToken } from "@/utils/auth";

export interface ResponseBody<T = any> {
  message: string;
  code: number;
  data?: T | T[];
}

// 这里是用于设定请求后端时，所用的 Token KEY
// 可以根据自己的需要修改，常见的如 Access-Token，Authorization
// 需要注意的是，请尽量保证使用中横线`-` 来作为分隔符，
// 避免被 nginx 等负载均衡器丢弃了自定义的请求头
export const REQUEST_TOKEN_KEY = "Access-Token";

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 6000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error: AxiosError): Promise<Promise<any>> => {
  if (error.response) {
    //const { data = {}, status, statusText } = error.response;
    // 403 无权限
    // if (status === 403) {
    //   notification.error({
    //     message: "Forbidden",
    //     description: (data && data.message) || statusText,
    //   });
    // }
    // // 401 未登录/未授权
    // if (status === 401) {
    //   notification.error({
    //     message: "" + status,
    //     description: data.msg || statusText,
    //   });
    // }
    // if (status === 409) {
    //   notification.error({
    //     message: "" + status,
    //     description: data.msg || statusText,
    //   });
    // }
  }
  return Promise.reject(error);
};

// 请求拦截器
const requestHandler = (
  config: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  // const savedToken = localStorage.get(STORAGE_TOKEN_KEY);
  // 如果 token 存在
  // 让每个请求携带自定义 token, 请根据实际情况修改
  if (getToken()) {
    if (config.headers) {
      config.headers["Content-Type"] = "application/json;charset=UTF-8";
      //config.headers[REQUEST_TOKEN_KEY] = "Bearer " + getToken();
      //config.headers["SystemId"] = 12;
      config.headers[REQUEST_TOKEN_KEY] = getToken() + "";
      //config.headers["token"] = getToken();
    }
  }
  return config;
};

// Add a request interceptor
request.interceptors.request.use(requestHandler, errorHandler);

// 响应拦截器
const responseHandler = (
  response: AxiosResponse
): ResponseBody<any> | AxiosResponse<any> | Promise<any> | any => {
  // if(response && response.data.code === 2001 || response.data.code ===1004) {
  //   message.success(response.data.msg);
  //   window.location.pathname = `/user`;
  //   localStorage.clear();
  // }
  return response.data;
};

// Add a response interceptor
request.interceptors.response.use(responseHandler, errorHandler);

export { AxiosResponse };

export default request;
