<template>
  <section class="user-body">
    <h3 class="set-title">关注列表</h3>
    <ul v-if="followList && followList.value && followList.value.length != 0">
      <li
        class="d-comment-item"
        v-for="(item, index) in followList.value"
        :key="index"
      >
        <img src="@/assets/user_icon.png" />
        <div class="text-contet">
          <h3>{{ item.name }}</h3>
          <p>
            <label>成员：</label>
            <span>{{ item.total_popular }}</span>
            <label class="follow-text">关注：</label>
            <span>{{ item.total_follow }}</span>
            <label class="follow-text">作品：</label>
            <span>{{ item.total_opus }}</span>
          </p>
        </div>
        <p class="cancel-icon" @click="cancel(item.artist_id)">
          <a-button>取消关注</a-button>
        </p>
      </li>
    </ul>
    <div class="no-data" v-else>暂无数据</div>
    <div class="d-pagination">
      <a-pagination
        v-model:current="currentNum"
        show-quick-jumper
        :total="total"
        @change="onChange"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import { message } from "ant-design-vue";
import { followApi, cancelFollowApi } from "@/api/user";

const followList = reactive([]);
const currentNum = ref(1);
const total = ref(0);

// 获取数据
const getData = () => {
  followApi({ page: currentNum.value, size: 10 }).then((res) => {
    if (res.code === 200) {
      followList.value = res.data.list;
      total.value = res.data.total;
    }
  });
};
const onChange = (pageNumber) => {
  currentNum.value = pageNumber;
  getData();
};
const cancel = (id) => {
  cancelFollowApi({ follow_id: id, type: "artist" }).then((res) => {
    if (res.code == 200) {
      message.success("取消关注成功");
      getData();
    }
  });
};
onMounted(() => {
  getData();
});
</script>
<style lang="less" scoped>
.user-body {
  text-align: left;
  padding-left: 50px;
  .set-title {
    color: #333;
    padding-left: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 15px;
    border-left: 2px solid #e74a48;
  }
  .d-comment-item {
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dbdbdb;
    img {
      width: 50px;
      height: 50px;
    }
    .text-contet {
      flex: 1;
      margin: 0 15px;
      h3 {
        line-height: 10px;
        color: #585858;
      }
    }
    label {
      color: #8e8e8e;
    }
    label:not(:first-child) {
      margin-left: 20px;
    }
  }
  .no-data {
    margin: 30px;
    text-align: center;
    color: #666;
  }
  .d-pagination {
    text-align: center;
  }
  .cancel-icon {
    cursor: pointer;
    .ant-btn {
      color: #e74a48;
      border-radius: 28px;
      border: 1px solid #e74a48;
    }
  }
}
</style>
