<!--
 * @心中有党，事业理想: 
 * @Author: Petrichor
 * @Date: 2022-05-05 20:35:28
 * @LastEditors: Petrichor
 * @LastEditTime: 2022-05-10 14:20:23
 * @Description: file content
 * @FilePath: \eart_pc\src\App.vue
-->
<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <div v-if="isLogin">
    <main-header></main-header>
    <router-view class="router-view" />
    <main-footer></main-footer>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { reactive, toRefs, computed } from "vue";
import mainHeader from "@/components/header.vue";
import mainFooter from "@/components/footer.vue";
import { useRoute } from "vue-router";

export default {
  components: {
    mainHeader,
    mainFooter,
  },
  setup() {
    const state = reactive({
      count: 0,
    });
    const route = useRoute();
    return {
      ...toRefs(state),
      isLogin: computed(() => {
        return !route.path.includes("user");
      }),
    };
  },
};
</script>
<style lang="less">
@import "./index.less";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.router-view {
  height: calc(100vh - 140px);
  overflow: hidden;
  overflow-y: auto;
  //height: 100%;
  background: #f8f8f8;
  //padding: @main-padding;
}
</style>
