import service from "@/utils/newRequest";
//修改密码
export const resetPwd = (data: any) => service.post("eart/account/reset", data);
//个人作品的上一页，下一页
export const opusTogglePage = (data: any) =>
  service.post("eart/opus/show", data);
//个人作品发布
export const personOpusRelease = (data: any) =>
  service.post("eart/opus/add", data);
export const showroomRelease = (data: any) =>
  service.post("eart/showroom/add", data);
export const vrRelease = (data: any) => service.post("eart/vr/add", data);
export const getOpusList = (data: any) => service.post("eart/opus/index", data);
export const getShowroomList = (data: any) =>
  service.post("eart/showroom/index", data);
export const getHomeVr = (data: any) => service.post("eart/vr/quality", data);
export const getVRList = (data: any) => service.post("eart/vr/index", data);
export const getTeamList = () => service.post("eart/team/list");
export const getTeamMemberList = (data: any) =>
  service.post("eart/team/member", data);
//团队申请
export const teamApply = (data: any) =>
  service.post("eart/team/application", data);
//团队成员拒绝操作
export const teamReject = (data: any) => service.post("eart/team/refuse", data);
export const teamAgree = (data: any) =>
  service.post("eart/team/approval", data);
//
export const publishDelete = (data: any) =>
  service.post("eart/account/delete", data);
