/*
 * @心中有党，事业理想:
 * @Author: Petrichor
 * @Date: 2022-05-05 20:35:28
 * @LastEditors: Petrichor
 * @LastEditTime: 2022-05-12 14:58:23
 * @Description: file content
 * @FilePath: \eart_pc\src\router\index.ts
 */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Person from "../views/personalCenter/Index.vue";
import Release from "@/views/personalCenter/Release.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/user",
    name: "User",
    component: () => import(/* webpackChunkName: "about" */ "@/views/user.vue"),
  },
  {
    path: "/search",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/search.vue"),
  },
  {
    path: "/showroomList",
    name: "ShowroomList",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/showroom/showroom-list.vue"
      ),
  },
  {
    path: "/showroomDetails",
    name: "ShowroomDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/showroom/showroom-details.vue"
      ),
  },
  {
    path: "/opusList",
    name: "OpusList",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/opus/opus-list.vue"),
  },
  {
    path: "/teamShow",
    name: "TeamShow",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/opus/team-show.vue"),
  },
  {
    path: "/opusDetails",
    name: "OpusDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/opus/opus-details.vue"),
  },
  {
    path: "/vrList",
    name: "VRList",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/vr-list.vue"),
  },
  {
    path: "/",
    name: "Person",
    component: Person,
    children: [
      {
        path: "/team",
        name: "Team",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/personalCenter/Team.vue"
          ),
      },
      {
        path: "/setting",
        name: "Setting",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/personalCenter/Setting.vue"
          ),
      },
      {
        path: "/release",
        name: "Release",
        component: Release,
        children: [
          {
            path: "/releaseInformation",
            name: "ReleaseInformation",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/personalCenter/ReleaseInformation.vue"
              ),
          },
          {
            path: "/publishList",
            name: "PublishList",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/personalCenter/PublishList.vue"
              ),
          },
        ],
      },
      {
        path: "/comment",
        name: "Comment",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/personalCenter/Comment.vue"
          ),
      },
      {
        path: "/follow",
        name: "Follow",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/personalCenter/Follow.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to: any) => {
  document.title == "eart_pc" &&
    (document.title = to.meta?.title || process.env.VUE_APP_TITLE);
});

export default router;
